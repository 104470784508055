/* https://d1133sjcnebqrc.cloudfront.net/fonts */
/* roboto-300normal - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: local("Roboto Light "), local("Roboto-Light"),
    url("https://d1133sjcnebqrc.cloudfront.net/fonts/roboto-latin-300.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("https://d1133sjcnebqrc.cloudfront.net/fonts/roboto-latin-300.woff")
      format("woff"); /* Modern Browsers */
}

/* roboto-300italic - latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src: local("Roboto Light italic"), local("Roboto-Lightitalic"),
    url("https://d1133sjcnebqrc.cloudfront.net/fonts/roboto-latin-300italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("https://d1133sjcnebqrc.cloudfront.net/fonts/roboto-latin-300italic.woff")
      format("woff"); /* Modern Browsers */
}

/* roboto-400normal - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local("Roboto Regular "), local("Roboto-Regular"),
    url("https://d1133sjcnebqrc.cloudfront.net/fonts/roboto-latin-400.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("https://d1133sjcnebqrc.cloudfront.net/fonts/roboto-latin-400.woff")
      format("woff"); /* Modern Browsers */
}

/* roboto-400italic - latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: local("Roboto Regular italic"), local("Roboto-Regularitalic"),
    url("https://d1133sjcnebqrc.cloudfront.net/fonts/roboto-latin-400italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("https://d1133sjcnebqrc.cloudfront.net/fonts/roboto-latin-400italic.woff")
      format("woff"); /* Modern Browsers */
}

/* roboto-500normal - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local("Roboto Medium "), local("Roboto-Medium"),
    url("https://d1133sjcnebqrc.cloudfront.net/fonts/roboto-latin-500.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("https://d1133sjcnebqrc.cloudfront.net/fonts/roboto-latin-500.woff")
      format("woff"); /* Modern Browsers */
}

/* roboto-500italic - latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src: local("Roboto Medium italic"), local("Roboto-Mediumitalic"),
    url("https://d1133sjcnebqrc.cloudfront.net/fonts/roboto-latin-500italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("https://d1133sjcnebqrc.cloudfront.net/fonts/roboto-latin-500italic.woff")
      format("woff"); /* Modern Browsers */
}
