@font-face {
  font-family: "iconfont";
  src: url("../iconfonts/iconfont.eot");
  src: url("../iconfonts/iconfont.eot?#iefix") format("embedded-opentype"),
    url("../iconfonts/iconfont.woff") format("woff"),
    url("../iconfonts/iconfont.ttf") format("truetype"),
    url("../iconfonts/iconfont.svg#iconfont") format("svg");
}

.b-icon {
  display: inline-block;
  font-family: "iconfont";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  font-size: 24px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: middle;
}

.b-icon span {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 24px;
  padding: 0 5px;
}

.b-icon-grade:before {
  content: "\e6a3";
}

.b-icon-invite:before {
  content: "\e6a2";
}

.b-icon-dropout:before {
  content: "\e6a1";
}

.b-icon-xialanormal:before {
  content: "\e6a0";
}

.b-icon-download2:before {
  content: "\e69f";
}

.b-icon-newannouncement:before {
  content: "\e69e";
}

.b-icon-sun:before {
  content: "\e82b";
}

.b-icon-lightning-bolt:before {
  content: "\e82a";
}

.b-icon-switch:before {
  content: "\e69d";
}

.b-icon-medium1:before {
  content: "\e694";
}

.b-icon-short:before {
  content: "\e695";
}

.b-icon-long:before {
  content: "\e696";
}

.b-icon-short_02:before {
  content: "\e698";
}

.b-icon-short_01:before {
  content: "\e699";
}

.b-icon-long_01:before {
  content: "\e69b";
}

.b-icon-long_02:before {
  content: "\e69c";
}

.b-icon-transfer:before {
  content: "\e697";
}

.b-icon-stepcompany:before {
  content: "\e81b";
}

.b-icon-steppartner:before {
  content: "\e81c";
}

.b-icon-annex:before {
  content: "\e816";
}

.b-icon-identity:before {
  content: "\e817";
}

.b-icon-info1:before {
  content: "\e818";
}

.b-icon-face:before {
  content: "\e819";
}

.b-icon-review:before {
  content: "\e81a";
}

.b-icon-RiskLimit:before {
  content: "\e693";
}

.b-icon-Stamp:before {
  content: "\e80e";
}

.b-icon-NextArrow:before {
  content: "\e6e8";
}

.b-icon-step1:before {
  content: "\e6e6";
}

.b-icon-step2:before {
  content: "\e6e7";
}

.b-icon-check1:before {
  content: "\e691";
}

.b-icon-close2:before {
  content: "\e692";
}

.b-icon-whitepaper:before {
  content: "\e68c";
}

.b-icon-browser:before {
  content: "\e68f";
}

.b-icon-website:before {
  content: "\e690";
}

.b-icon-fire:before {
  content: "\e68a";
}

.b-icon-recharge:before {
  content: "\e68b";
}

.b-icon-ightning:before {
  content: "\e68d";
}

.b-icon-ended:before {
  content: "\e68e";
}

.b-icon-subtract:before {
  content: "\e689";
}

.b-icon-ScanQR:before {
  content: "\e6e5";
}

.b-icon-Phone:before {
  content: "\e6e2";
}

.b-icon-PC:before {
  content: "\e6e4";
}

.b-icon-radio-checked:before {
  content: "\e7f8";
}

.b-icon-startingtime:before {
  content: "\e6b7";
}

.b-icon-googleplay:before {
  content: "\e688";
}

.b-icon-coingecko:before {
  content: "\e687";
}

.b-icon-feixiaohao:before {
  content: "\e684";
}

.b-icon-mytoken:before {
  content: "\e685";
}

.b-icon-coinmarketcap:before {
  content: "\e686";
}

.b-icon-delete1:before {
  content: "\e682";
}

.b-icon-save:before {
  content: "\e683";
}

.b-icon-levelunlimited:before {
  content: "\e681";
}

.b-icon-levelwithdraw:before {
  content: "\e67e";
}

.b-icon-levelcheck:before {
  content: "\e67f";
}

.b-icon-levellimited:before {
  content: "\e680";
}

.b-icon-triangle:before {
  content: "\e67c";
}

.b-icon-suspended1:before {
  content: "\e67d";
}

.b-icon-delete:before {
  content: "\e679";
}

.b-icon-checkedRadio2:before {
  content: "\e67b";
}

.b-icon-edit2:before {
  content: "\e67a";
}

.b-icon-flod:before {
  content: "\e677";
}

.b-icon-unflod:before {
  content: "\e678";
}

.b-icon-qq:before {
  content: "\e676";
}

.b-icon-testfight:before {
  content: "\e675";
}

.b-icon-medium:before {
  content: "\e66f";
}

.b-icon-linkedin:before {
  content: "\e670";
}

.b-icon-github:before {
  content: "\e671";
}

.b-icon-line:before {
  content: "\e672";
}

.b-icon-discord:before {
  content: "\e673";
}

.b-icon-biyong:before {
  content: "\e674";
}

.b-icon-count:before {
  content: "\e66e";
}

.b-icon-indicator:before {
  content: "\e66d";
}

.b-icon-contract:before {
  content: "\e66c";
}

.b-icon-dark:before {
  content: "\e669";
}

.b-icon-whiite:before {
  content: "\e66a";
}

.b-icon-cnyswitch:before {
  content: "\e66b";
}

.b-icon-download1:before {
  content: "\e656";
}

.b-icon-kline:before {
  content: "\e659";
}

.b-icon-Sort_left:before {
  content: "\e65a";
}

.b-icon-moreContent:before {
  content: "\e65d";
}

.b-icon-leftmore:before {
  content: "\e65e";
}

.b-icon-fullScreen:before {
  content: "\e65f";
}

.b-icon-Sort_right:before {
  content: "\e661";
}

.b-icon-moment:before {
  content: "\e662";
}

.b-icon-search1:before {
  content: "\e664";
}

.b-icon-check:before {
  content: "\e666";
}

.b-icon-screening:before {
  content: "\e667";
}

.b-icon-share1:before {
  content: "\e668";
}

.b-icon-order_down:before {
  content: "\e663";
}

.b-icon-order_up:before {
  content: "\e665";
}

.b-icon-slider:before {
  content: "\e62f";
}

.b-icon-notice:before {
  content: "\e660";
}

.b-icon-trade:before {
  content: "\e657";
}

.b-icon-asset:before {
  content: "\e658";
}

.b-icon-markets:before {
  content: "\e65b";
}

.b-icon-home:before {
  content: "\e65c";
}

.b-icon-back:before {
  content: "\e655";
}

.b-icon-search:before {
  content: "\e654";
}

.b-icon-paste1:before {
  content: "\e653";
}

.b-icon-brief:before {
  content: "\e652";
}

.b-icon-windows:before {
  content: "\e635";
}

.b-icon-weibo:before {
  content: "\e636";
}

.b-icon-telegram:before {
  content: "\e637";
}

.b-icon-apple:before {
  content: "\e638";
}

.b-icon-facebook:before {
  content: "\e63a";
}

.b-icon-android:before {
  content: "\e63b";
}

.b-icon-locked:before {
  content: "\e63c";
}

.b-icon-favorite:before {
  content: "\e63d";
}

.b-icon-favorited:before {
  content: "\e63e";
}

.b-icon-candels:before {
  content: "\e63f";
}

.b-icon-settings:before {
  content: "\e640";
}

.b-icon-day:before {
  content: "\e641";
}

.b-icon-night:before {
  content: "\e642";
}

.b-icon-announcement:before {
  content: "\e643";
}

.b-icon-pin:before {
  content: "\e644";
}

.b-icon-fullscreen:before {
  content: "\e645";
}

.b-icon-network:before {
  content: "\e646";
}

.b-icon-icon-test1:before {
  content: "\e648";
}

.b-icon-twitter:before {
  content: "\e64a";
}

.b-icon-intro:before {
  content: "\e639";
}

.b-icon-edit:before {
  content: "\e64b";
}

.b-icon-info_line:before {
  content: "\e64c";
}

.b-icon-appdownload:before {
  content: "\e64d";
}

.b-icon-announce:before {
  content: "\e64e";
}

.b-icon-download:before {
  content: "\e64f";
}

.b-icon-reddit:before {
  content: "\e650";
}

.b-icon-Icon_Authentication:before {
  content: "\e651";
}

.b-icon-dianhua:before {
  content: "\e609";
}

.b-icon-v:before {
  content: "\e69a";
}

.b-icon-loading:before {
  content: "\e615";
}

.b-icon-paypal:before {
  content: "\e89e";
}

.b-icon-send:before {
  content: "\e605";
}

.b-icon-kong:before {
  content: "\e600";
}

.b-icon-addPicture:before {
  content: "\e647";
}

.b-icon-usdt:before {
  content: "\e649";
}

.b-icon-alarm:before {
  content: "\e601";
}

.b-icon-arrowDown:before {
  content: "\e602";
}

.b-icon-close:before {
  content: "\e603";
}

.b-icon-attachment:before {
  content: "\e604";
}

.b-icon-filter:before {
  content: "\e606";
}

.b-icon-firstPage:before {
  content: "\e607";
}

.b-icon-fold:before {
  content: "\e608";
}

.b-icon-lastPage:before {
  content: "\e60a";
}

.b-icon-hidden:before {
  content: "\e60b";
}

.b-icon-moreAction:before {
  content: "\e60c";
}

.b-icon-nextPage:before {
  content: "\e60d";
}

.b-icon-prevPage:before {
  content: "\e60e";
}

.b-icon-Suspended:before {
  content: "\e60f";
}

.b-icon-unfold:before {
  content: "\e610";
}

.b-icon-unhidden:before {
  content: "\e611";
}

.b-icon-arrowUp:before {
  content: "\e612";
}

.b-icon-add:before {
  content: "\e613";
}

.b-icon-arrowRight:before {
  content: "\e614";
}

.b-icon-arrowLeft:before {
  content: "\e616";
}

.b-icon-checked:before {
  content: "\e617";
}

.b-icon-cancelled1:before {
  content: "\e61a";
}

.b-icon-finished1:before {
  content: "\e61d";
}

.b-icon-foldPage:before {
  content: "\e61e";
}

.b-icon-paste:before {
  content: "\e622";
}

.b-icon-lightsOFF:before {
  content: "\e625";
}

.b-icon-lightsON:before {
  content: "\e626";
}

.b-icon-transaction:before {
  content: "\e627";
}

.b-icon-unfoldPage:before {
  content: "\e628";
}

.b-icon-bankCard:before {
  content: "\e618";
}

.b-icon-wechatPay:before {
  content: "\e619";
}

.b-icon-alipay:before {
  content: "\e61b";
}

.b-icon-uncheck:before {
  content: "\e61c";
}

.b-icon-user:before {
  content: "\e61f";
}

.b-icon-checkedRadio:before {
  content: "\e620";
}

.b-icon-ucheckedRadio:before {
  content: "\e621";
}

.b-icon-time:before {
  content: "\e623";
}

.b-icon-code1:before {
  content: "\e624";
}

.b-icon-positive:before {
  content: "\e629";
}

.b-icon-reverse:before {
  content: "\e62a";
}

.b-icon-comment:before {
  content: "\e62b";
}

.b-icon-info:before {
  content: "\e62c";
}

.b-icon-praised:before {
  content: "\e62d";
}

.b-icon-upload:before {
  content: "\e62e";
}

.b-icon-logo:before {
  content: "\e631";
}

.b-icon-share:before {
  content: "\e632";
}

.b-icon-praise1:before {
  content: "\e633";
}

.b-icon-icon-test:before {
  content: "\e630";
}

.b-icon-wechat:before {
  content: "\e634";
}
.b-icon-youtube:before {
  content: "\e6a4";
}
